<div *ngIf="hiddenConfig != true">
  <ng-container [ngSwitch]="stateService.getState() | async">
    <cc-chat-detail
      class="logate-chat-chat"
      [style.background]="(configService.config$ | async)?.color"
      [@openClose]
      *ngSwitchCase="state.DETAIL"
    >
    </cc-chat-detail>

    <cc-chat-interaction
      class="logate-chat-chat"
      [style.background]="(configService.config$ | async)?.color"
      [@openClose]
      *ngSwitchCase="state.INTERACTION"
    >
    </cc-chat-interaction>

    <cc-chat-interaction
      class="logate-chat-chat"
      [style.background]="(configService.config$ | async)?.color"
      [@openClose]
      *ngSwitchCase="state.DISABLED_INTERACTION"
    >
    </cc-chat-interaction>
    <cc-chat-customer-experience
      class="logate-chat-chat"
      [style.background]="(configService.config$ | async)?.color"
      [@openClose]
      *ngSwitchCase="state.CUSTOMER_EXPERIENCE"
    >
    </cc-chat-customer-experience>

    <cc-chat-minimized
      class="logate-chat-chat-position"
      [@openClose]
      *ngSwitchCase="state.MINIMIZED"
    >
    </cc-chat-minimized>
  </ng-container>
</div>
