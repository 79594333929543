<form class="logate-chat-detail-term" [formGroup]="form">
  <label class="logate-chat-detail-term__group">
    <span class="logate-chat-detail-term__group--container">
      <input type="checkbox" formControlName="termsOfUse" />
      <span class="logate-chat-detail-term__checkmark"></span>
    </span>
    <span
      class="logate-chat-detail-term__text logate-chat-detail-term__text--with-link"
    >
      {{ "detail.terms.agree" | translate }}
      <a
        href="{{ (configService.config$ | async)?.termsUrl }}"
        target="_blank"
        class="logate-chat-detail-term__link"
        >{{ "detail.terms.agree2" | translate }}</a
      >
    </span>
  </label>
  <label class="logate-chat-detail-term__group">
    <span class="logate-chat-detail-term__group--container">
      <input type="checkbox" formControlName="acceptTerms" />
      <span class="logate-chat-detail-term__checkmark"></span>
    </span>
    <span class="logate-chat-detail-term__text">
      {{ "detail.terms.consent" | translate }}
    </span>
  </label>
</form>
