import { ApplicationRef, APP_INITIALIZER, DoBootstrap, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { createCustomElement } from '@angular/elements';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from './core/services/message.service';
import { SessionService } from './core/services/session.service';
import { SocketConnectionService } from './core/services/socket-connection.service';
import { EventService } from './core/services/event.service';
import { ChatComponent } from './chat/chat.component';
import { DetailModule } from './chat/detail/detail.module';
import { InteractionModule } from './chat/interaction/interaction.module';
import { CustomerExperienceModule } from './chat/customer-experience/customer-experience.module';
import { MinimizedModule } from './chat/minimized/minimized.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { UnreadMessageService } from './core/services/unread-message.service';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { RxStompService } from '@stomp/ng2-stompjs';
import { InitService } from './chat/initializer/init.service';
import { Observable } from 'rxjs';
import { VisibilityService } from './chat/visibility/visibility.service';

function initializeApp(initService: InitService): () => Observable<any> {
  console.log("INIT APP");
  return () => initService.init();
}

export function HttpLoaderFactory(http: HttpClient) {
  let origin = '.';
  let pathname = '';
  const logateChatElement = document.getElementById('logate-chat');
  if (logateChatElement) {
    const sourceLocation = new URL((logateChatElement as any)?.src);
    origin = sourceLocation?.origin;
    pathname = sourceLocation?.pathname.substring(
      0,
      sourceLocation?.pathname.lastIndexOf('/')
    );
  }

  return new TranslateHttpLoader(
    http,
    origin + pathname + '/assets/i18n/',
    '.json'
  );
}
@NgModule({
  declarations: [ChatComponent, AppComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    DetailModule,
    InteractionModule,
    CustomerExperienceModule,
    MinimizedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    MessageService,
    SessionService,
    UnreadMessageService,
    SocketConnectionService,
    EventService,
    InitService,
    VisibilityService,
    {
      provide: RxStompService,
      useFactory: () => new RxStompService(),
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation,
    },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [InitService], multi: true}

  ],
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap(appRef: ApplicationRef): void {
    const el = createCustomElement(ChatComponent, { injector: this.injector });
    customElements.define('logate-chat', el);

    if (!environment.production) {
      appRef.bootstrap(AppComponent);
    }
  }
}

export function getBaseLocation() {
  let paths: string[] = location.pathname.split('/').splice(1, 1);
  let basePath: string = '/' + ((paths && paths[0]) || ''); // Default: /
  return basePath;
}
