<div class="logate-chat-detail">
  <div class="logate-chat-detail__header">
    <div
      [style.background-image]="
        (configService.config$ | async)?.logoBackgroundImageUrl
          ? 'url(' +
            (configService.config$ | async)?.logoBackgroundImageUrl +
            ')'
          : ''
      "
      class="logate-chat-detail__header__logo"
    ></div>
    <span class="logate-chat-detail__header--close" (click)="close()"></span>
  </div>
  <div class="logate-chat-detail__content">
    <div class="logate-chat-detail__content--title">LOGATE</div>
    <!-- <div class="detail__content--subtitle">
      Naša online podrška je na raspologanja za Vaša pitanja i sugestije.
    </div> -->

    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <cc-chat-detail-form [form]="form"></cc-chat-detail-form>
      <cc-chat-detail-term [form]="form"></cc-chat-detail-term>

      <button
        class="logate-chat-detail__submit"
        type="submit"
        [disabled]="!isFormValid"
        [style.color]="(configService.config$ | async)?.submitButtonColor"
        [style.background-color]="
          (configService.config$ | async)?.submitButtonBackground
        "
      >
        {{ "detail.begin-chat" | translate }}
      </button>
    </form>
  </div>
  <div class="logate-chat-detail__logate-logo"></div>
</div>
