import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { State } from 'app/core/enums/state.enum';
import { ConfigService } from 'app/core/services/config.service';
import { EntryDataService } from 'app/core/services/entry-data.service';

import { StateService } from '../../core/services/state.service';
import { UnreadMessageService } from '../../core/services/unread-message.service';
import { InitService } from '../initializer/init.service';
import { InteractionStateService } from '../interaction/services/interaction-state.service';

@Component({
  selector: 'cc-chat-minimized',
  templateUrl: './minimized.component.html',
  styleUrls: ['./minimized.component.scss'],
})
export class MinimizedComponent implements OnInit {
  messageCounter: number;
  state: State;

  constructor(
    public unreadMessageService: UnreadMessageService,
    private stateService: StateService,
    public configService: ConfigService,
    private entryDataService: EntryDataService,
    private cd: ChangeDetectorRef
  ) {
    this.stateService.getState().subscribe((state) => {
      this.state = state;
    });
  }

  ngOnInit(): void {
    this.unreadMessageService.unreadMessages$.subscribe((count) => {
      this.messageCounter = count;
      this.cd.detectChanges();
    });
  }

  open(): void {
    const eBankingData = this.configService.getEBankingData();
    // if there is eBankingData sent, check if there is previous state
    // if there is no previous state, it means that user opens chat for the first time
    if (
      (eBankingData && !this.stateService.getPreviousState()) ||
      this.stateService.getPreviousState() === this.state
    ) {
      this.entryDataService.initializeChatWithEntryData(
        eBankingData,
        eBankingData?.email != '' ? eBankingData?.email : eBankingData?.phone
      );
    } else if (
      !eBankingData &&
      this.stateService.getPreviousState() === this.state
    ) {
      const eBankingDataStorage = this.entryDataService.getEntryData();
      this.entryDataService.initializeChatWithEntryData(
        eBankingDataStorage,
        eBankingDataStorage?.email != ''
          ? eBankingDataStorage?.email
          : eBankingDataStorage?.phone
      );
    } else {
      this.stateService.setToPreviousState();
    }
  }

  publicFunc() {
    console.log('OJ HA');
  }
}
