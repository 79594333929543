import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EntryData } from 'app/core/models/entry-data';
import { EntryDataService } from 'app/core/services/entry-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cc-chat-detail-form',
  templateUrl: './detail-form.component.html',
  styleUrls: ['./detail-form.component.scss']
})
export class DetailFormComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  private static readonly emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  private static readonly phoneRegex = /^[\+]?[0-9]{3,20}$/;

  destroy$ = new Subject<void>();
  entryData: EntryData;

  constructor(private entryDataService: EntryDataService) {
    this.entryData = this.entryDataService.getEntryData();
   }

  ngOnInit(): void {
    this.prepareFormControls();
    this.form.controls.contact.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val.match(DetailFormComponent.emailRegex)) {
        this.form.controls.email.setValue(val);
      } else if (val.match(DetailFormComponent.phoneRegex)) {
        this.form.controls.phone.setValue(val);
      } else {
        this.form.controls.phone.setValue('');
        this.form.controls.email.setValue('');
      }
    })
  }

  prepareFormControls(): void {
    this.form.addControl('nickname', new UntypedFormControl(this.entryData?.nickname, Validators.required));
    this.form.addControl('contact', new UntypedFormControl(this.entryData?.email != '' ? this.entryData?.email : this.entryData?.phone, [Validators.required, this.validateInput]));
    this.form.addControl('email', new UntypedFormControl(this.entryData?.email));
    this.form.addControl('phone', new UntypedFormControl(this.entryData?.phone));
  }

  validateInput(c: UntypedFormControl) {
    return (DetailFormComponent.emailRegex.test(c.value) || DetailFormComponent.phoneRegex.test(c.value)) ? null : {
      validateInput: {
        valid: false
      }
    };
  }

  get contact(): AbstractControl {
    return this.form.get('contact');
  }

  get nickname(): AbstractControl {
    return this.form.get('nickname');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
