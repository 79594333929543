import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { EntryData } from '../models/entry-data';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config = new BehaviorSubject<IConfig>(null);
  config$ = this.config.asObservable();

  eBankingData = new BehaviorSubject<EntryData>(null);
  eBankingData$ = this.eBankingData.asObservable();

  hidden = new BehaviorSubject<boolean>(null);
  hidden$ = this.hidden.asObservable();

  showonclose = new BehaviorSubject<boolean>(null);
  showonclose$ = this.showonclose.asObservable();

  getHidden() {
    return this.hidden.value;
  }

  setShowonclose(showonclose: boolean) {
    this.showonclose.next(showonclose);
  }

  getShowonclose() {
    return this.showonclose.value;
  }

  setHidden(hidden: boolean) {
    this.hidden.next(hidden);
  }

  getConfig() {
    return this.config.value;
  }

  setConfig(config: IConfig) {
    console.log('Setting config', config);
    this.config.next(config);
  }

  setEBankingData(eBankingData: EntryData) {
    this.eBankingData.next(eBankingData);
  }

  getEBankingData() {
    return this.eBankingData.value;
  }

  destroyService() {
    this.config.next(null);
  }
}
export class IConfig {
  clientId: string;
  logoBackgroundImageUrl: string;
  operatorLogoBackgroundImageUrl: string;
  color: string;
  termsUrl: string;
  submitButtonColor: string;
  submitButtonBackground: string;
  interactionMessagesBackground: string;
  fontFamily: string;
}
