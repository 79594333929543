<form class="logate-chat-detail-form" [formGroup]="form">
  <div class="logate-chat-detail-form__group">
    <div class="logate-chat-detail-form__title">
      {{ "detail.form.enter-name" | translate }}
    </div>
    <input
      class="logate-chat-detail-form__input"
      [ngClass]="{
        'logate-chat-detail-form__input--valid':
          (nickname.touched || nickname.dirty) && nickname.valid,
        'logate-chat-detail-form__input--error':
          (nickname.touched || nickname.dirty) && nickname.invalid
      }"
      placeholder="{{ 'detail.form.enter-name-placeholder' | translate }}"
      formControlName="nickname"
    />
  </div>

  <div class="logate-chat-detail-form__group">
    <div class="logate-chat-detail-form__title">
      {{ "detail.form.enter-email-number" | translate }}
    </div>
    <input
      class="logate-chat-detail-form__input"
      placeholder="{{
        'detail.form.enter-email-number-placeholder' | translate
      }}"
      formControlName="contact"
    />
    <div
      class="logate-chat-detail-form__input--txt-error"
      *ngIf="
        form.controls.contact?.errors?.pattern && form.controls.contact?.touched
      "
    >
      {{ "detail.form.enter-email-number-error" | translate }}
    </div>
  </div>
</form>
